import React from "react";
import { Link } from 'gatsby';

const LegalFooter = () => (
    <p className="legal-pages">
        <Link to="/legal-advice">Aviso Legal</Link> | 
        <Link to="/privacy-policy">Política de privacidad</Link> | 
        <Link to="/cookies-policy">Política de cookies</Link>
    </p>
)

export default LegalFooter;
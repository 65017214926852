import React from 'react';
import { changeLocale, injectIntl } from 'gatsby-plugin-intl';
import Image from '../../image';

class LanguageSwitcher extends React.Component
{
  constructor(props)
  {
    super(props);
    this.changeLocale = this.changeLocale.bind(this);

  }
  changeLocale(language)
  {
    changeLocale(language);
  }

  render()
  {
    return <>
      {['es', 'br', 'en'].map((language, index) => {
        if (language !== this.props.intl.locale)
        {
          return <span key={index} className="flag" onClick={() => this.changeLocale(language)}>
            <Image filename={"flag-"+language+'.png'} />
          </span>
        }
        
        return null;
      })}
    </>
  }
}
  

export default injectIntl(LanguageSwitcher);
import React from "react"
import LanguageSwitcher from "./languageSwitcher";

const TopHeaderMenu = () => (
  <div className="top-header-menu">
    <div className="phone">
        <a href="tel://+34-91-193-00-61">+34 91 193 00 61</a>
    </div>
    <div className="email">
        <a href="mailto:info@adventurees.com">
            info@adventurees.com
        </a>
    </div>
    <LanguageSwitcher />
  </div>
)

export default TopHeaderMenu;

import React from "react"

import SiteBrand from './site-brand';
import HeaderMenu from './header-menu';

const ContentHeader = (className) =>
(
  <div className="content-header">
    <div className="container">
        <SiteBrand className={className}/>
        <HeaderMenu />
    </div>
  </div>
);

export default ContentHeader;

import React from "react";
import { Link } from 'gatsby';

const HeaderMenuItem = ({label, link}) => {
  return <li className="dropdown">
          <Link to={link}>{label}</Link>
        </li>
}

export default HeaderMenuItem;

import React from "react"

import SocialsTop from './socials-top';
import TopHeaderMenu from './top-header-menu';

import '../../../styles/layout/header/top-header.less';

const TopHeader = () => (
  <div className="top-header clearfix">
    <div className="container">
        <SocialsTop />
        <TopHeaderMenu />
    </div>
  </div>
)

export default TopHeader;
import React from "react";

import AdvTitle from './advTitle';

const Section = ({children, title, subtitle, className=''}) => (
  <section className={"section "+className}>
      <div className="container">
        <AdvTitle title={title} subtitle={subtitle} />
        {children}
      </div>
  </section>
)

export default Section;

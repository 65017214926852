import React from "react"
import { injectIntl } from 'gatsby-plugin-intl';
import HeaderMenuItem from './header-menu-item';

class HeaderMenu extends React.Component
{
    toggleHamburgerMenu()
    {
        var body_element = document.body;
        var menu_element = document.getElementsByClassName('main-menu')[0];
        var hamburger_element = document.getElementsByClassName('c-hamburger')[0];

        // Closing menu
        if (body_element.classList.contains('menu-open'))
        {
            body_element.classList.remove('menu-open');
            menu_element.classList.remove('open');
            hamburger_element.classList.remove('is-active');
        }

        // Opening menu
        else
        {
            body_element.classList.add('menu-open');
            menu_element.classList.add('open');
            hamburger_element.classList.add('is-active');
        }
    }

    render()
    {
        return <div className="right-header">
            <nav className="main-menu">
                <button className="c-hamburger c-hamburger--htx" onClick={() => this.toggleHamburgerMenu()}><span></span></button>

                <ul>
                    <HeaderMenuItem label={this.props.intl.formatMessage({id: "Products"})} link="/products" />
                    <HeaderMenuItem label={this.props.intl.formatMessage({id: "Services"})} link="/services" />
                    <HeaderMenuItem label={this.props.intl.formatMessage({id: "Clients"})}  link="/clients" />
                    <HeaderMenuItem label={this.props.intl.formatMessage({id: "Contact"})}  link="/contact" />
                </ul>
            </nav>
        </div>;
    }
}

export default injectIntl(HeaderMenu);

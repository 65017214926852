import React from "react";

import SocialTopNetwork from '../header/social-top-network';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

const SocialsFooter = () => (
    <ul className="socials-footer">
        <SocialTopNetwork icon={faFacebookF} link="https://www.facebook.com/pages/Adventureros/1440045032903915" network="facebook" />
        <SocialTopNetwork icon={faTwitter} link="https://twitter.com/AdventureesGO" network="twitter" />
        <SocialTopNetwork icon={faLinkedinIn} link="https://www.linkedin.com/company/adventureros/" network="linkedin" />
        <SocialTopNetwork icon={faInstagram} link="https://www.instagram.com/adventurees.go/" network="instagram" />
    </ul>
)

export default SocialsFooter;
import React from "react";

import "../styles/adv-page-title.less";

const AdvTitle = ({title, subtitle}) => (
  <div className="adv-page-title">
      <h2 className="title">{title}</h2>
      {subtitle && <p className="subtitle">{subtitle}</p>}
  </div>
)

export default AdvTitle;

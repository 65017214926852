import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import BackgroundImage from 'gatsby-background-image';

import "../styles/layout/footer.less";
import Section from "./section";
import SocialsFooter from "./layout/footer/socialsFooter";
import LegalFooter from "./layout/footer/legal-footer";

const Footer = ({intl}) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "footer.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {

      return <footer>
        <BackgroundImage
          Tag="div"
          className="footer-top"
          fluid={data.image.childImageSharp.fluid}
          >
              <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 space-d-20 footer-notice">
                        <div>
                          <h2>
                            {intl.formatMessage({id: "About us"})}
                          </h2>
                        </div>
                        <p>{intl.formatMessage({id: "In Adventurees, we work with a maximum objective: to facilitate communities to develop their businesses and attract capital."})}</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-trigger">
                        <a href="tel:+34-91-193-00-61">{intl.formatMessage({id: "Call us"})}</a>
                      </div>
                      <div className="form-trigger">
                        <Link to="/contact">{intl.formatMessage({id: "Write us"})}</Link>
                      </div>
                    </div>
                </div>
              </div>
          </BackgroundImage>
          <Section>
            <SocialsFooter />
            <p className="copyright-legal-notice">Adventurees.com &copy; 2019</p>
            <LegalFooter />
          </Section>
        </footer>
    }}
  />
);

export default injectIntl(Footer);